import React, { useState, useRef, useEffect } from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from "@fullcalendar/list"
import interactionPlugin from "@fullcalendar/interaction"
import MyListBox from "./Components/MyListBox"
import useBreakpoint from "./Hooks/useBreakpoint"
import SimpleReactCalendar from "simple-react-calendar"
import { database } from "./firebaseConfig"
import { collection, query, addDoc, deleteDoc, updateDoc, doc, getDocs, where } from "firebase/firestore"

const dbInstance = collection(database, "events")

function App(props) {
  const [innerHeight, setInnerHeight] = useState(null)
  const [calendarHeight, setCalendarHeight] = useState(null)
  const breakPoint = useBreakpoint()
  const CalendarRef = useRef()
  const TrashRef = useRef()
  const MiniCalendarRef = useRef()
  const [dateInfo, setDateInfo] = useState(null)
  const [dateDisplay, setDateDisplay] = useState(null)
  const [currentView, setCurrentView] = useState("timeGridWeek")
  const [currentDate, setCurrentDate] = useState(new Date())
  const [events, setEvents] = useState([])
  const [isDataFetched, setIsDataFetched] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", function () {
      setInnerHeight(window.innerHeight)
    })

    const fetchData = async () => {
      await getEvents()
      setIsDataFetched(true)
    }

    fetchData()
  }, [])

  const getEvents = () => {
    let theQuery = query(dbInstance, where("start", ">=", "2022-08-08T00:00:00"))

    getDocs(theQuery).then((data) => {
      let theEvents = data.docs.map((item) => {
        return { ...item.data(), id: item.id }
      })
      setEvents(theEvents)
      console.log(theEvents)
    })
  }

  useEffect(() => {
    if (CalendarRef.current) {
      if (breakPoint === "xs" || breakPoint === "sm" || breakPoint === "md") {
        if (currentView === "timeGridWeek") {
          CalendarRef.current.getApi().changeView("timeGridWeek")
          setCurrentView("timeGridWeek")
        } else if (currentView === "dayGridMonth") {
          CalendarRef.current.getApi().changeView("listMonth")
          setCurrentView("listMonth")
        }
      } else {
        if (currentView === "timeGridWeek") {
          CalendarRef.current.getApi().changeView("timeGridWeek")
          setCurrentView("timeGridWeek")
        } else if (currentView === "listMonth") {
          CalendarRef.current.getApi().changeView("dayGridMonth")
          setCurrentView("dayGridMonth")
        }
      }
    }
    // On met a jour les hauteurs
    if (breakPoint === "xl") {
      setCalendarHeight(window.innerHeight - 64)
    } else {
      setCalendarHeight(window.innerHeight - 121)
    }
  }, [breakPoint, currentView])

  const updateView = (view) => {
    if (view[0] === "Mois") {
      if (breakPoint === "xs" || breakPoint === "sm" || breakPoint === "md") {
        CalendarRef.current.getApi().changeView("listMonth")
        setCurrentView("listMonth")
      } else {
        CalendarRef.current.getApi().changeView("dayGridMonth")
        setCurrentView("dayGridMonth")
      }
    } else if (view[0] === "Semaine") {
      if (breakPoint === "xs" || breakPoint === "sm" || breakPoint === "md") {
        CalendarRef.current.getApi().changeView("timeGridWeek")
        setCurrentView("timeGridWeek")
      } else {
        CalendarRef.current.getApi().changeView("timeGridWeek")
        setCurrentView("timeGridWeek")
      }
    }
  }

  useEffect(() => {
    if (dateInfo === null && !CalendarRef.current) {
      return
    }

    const monthNames = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"]

    if (currentView === "timeGridWeek" || currentView === "timeGridWeek") {
      // Revoir la fonction getWeek, elle fonctionne pas
      Date.prototype.getWeek = function () {
        var date = new Date(this.getTime())
        date.setHours(0, 0, 0, 0)
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7))
        // January 4 is always in week 1.
        var week1 = new Date(date.getFullYear(), 0, 4)
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7)
      }

      var realDayInfoEnd = new Date(dateInfo.end.setHours(dateInfo.end.getHours() - 12)).getDate()
      var realMonthInfoEnd = new Date(dateInfo.end.setHours(dateInfo.end.getHours() - 12)).getMonth()
      var realYearInfoEnd = new Date(dateInfo.end.setHours(dateInfo.end.getHours() - 12)).getFullYear()

      let monthStart = monthNames[dateInfo.start.getMonth()]
      const monthEnd = monthNames[realMonthInfoEnd]
      if (monthStart === monthEnd) {
        monthStart = ""
      } else {
        monthStart = " " + monthStart
      }

      let yearStart = dateInfo.start.getFullYear()
      const yearEnd = realYearInfoEnd

      if (yearStart === yearEnd) {
        yearStart = ""
      } else {
        yearStart = " " + yearStart
      }

      setDateDisplay(
        dateInfo.start.getDate() +
          monthStart +
          " " +
          yearStart +
          " - " +
          realDayInfoEnd +
          " " +
          monthEnd +
          " " +
          realYearInfoEnd +
          " (semaine " +
          CalendarRef.current.getApi().getDate().getWeek() +
          ")"
      )
    } else {
      function capitalizeFirstLetter(string) {
        if (string.length > 0) {
          return string.charAt(0).toUpperCase() + string.slice(1)
        }
        return null
      }

      var currentMonth = monthNames[CalendarRef.current.getApi().getDate().getMonth()]
      var currentYear = CalendarRef.current.getApi().getDate().getFullYear()

      setDateDisplay(capitalizeFirstLetter(currentMonth) + " " + currentYear)
    }

    setCurrentDate(new Date(CalendarRef.current.getApi().getDate()))
  }, [dateInfo, currentView])

  const addEvent = (selectInfo) => {
    let a = prompt("Nom de la réservation")
    if (a != null) {
      addDoc(dbInstance, {
        title: a,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        backgroundColor: "#E8F412",
        borderColor: "#E8F412",
        textColor: "#294B46",
        extendedProps: { accentColor: "quartary" },
      })
      CalendarRef.current.getApi().addEvent({
        title: a,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        backgroundColor: "#E8F412",
        borderColor: "#E8F412",
        textColor: "#294B46",
        extendedProps: { accentColor: "quartary" },
      })
    }
  }

  const isEventOverDiv = function (x, y) {
    const element = TrashRef.current
    const rect = element.getBoundingClientRect()
    const offset = {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
      right: rect.right + window.scrollX,
      bottom: rect.bottom + window.scrollY,
    }

    // Compare
    if (x >= offset.left && y >= offset.top && x <= offset.right && y <= offset.bottom) {
      return true
    }
    return false
  }

  const deleteEvent = async (selectInfo) => {
    if (window.confirm("Voulez-vous vraiment supprimer cette réservation ?")) {
      await deleteDoc(doc(database, "events", selectInfo.event._def.publicId))
      selectInfo.event.remove()
    }
  }

  const updateEvent = (selectInfo) => {
    const collectionById = doc(database, "events", selectInfo.event._def.publicId)
    updateDoc(collectionById, {
      start: selectInfo.event.startStr,
      end: selectInfo.event.endStr,
    })
  }

  if (!isDataFetched)
    return (
      <div className="w-screen h-screen overflow-hidden flex flex-col items-center justify-center bg-ral1026">
        <svg id="Groupe_3" width="44" height="44" viewBox="0 0 44 44" className="animate-bounce">
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectangle_1" data-name="Rectangle 1" width="17.913" height="25.171" fill="#fff" />
            </clipPath>
          </defs>
          <circle id="Ellipse_1" data-name="Ellipse 1" cx="22" cy="22" r="22" fill="#ffffff" />
          <g id="Groupe_2" data-name="Groupe 2" transform="translate(12 9)">
            <g id="Groupe_1" data-name="Groupe 1" transform="translate(0 0)" clipPath="url(#clip-path)">
              <path
                id="Tracé_1"
                data-name="Tracé 1"
                d="M12.285,11.779a18.432,18.432,0,0,0-2.33-.187c-2.966,0-3.964,2.216-3.964,5.181,0,2.185.787,4.808,3.632,4.87a15.353,15.353,0,0,0,2.662-.157Zm5.628,12.892c-.484.03-4.782.5-7.778.5a13.759,13.759,0,0,1-5.627-.749C1.482,23.078,0,20.019,0,16.711c0-3.277,1.421-6.586,4.447-7.959a10.937,10.937,0,0,1,4.146-.687,28.211,28.211,0,0,1,3.662.25V1.042L17.913,0Z"
                transform="translate(0 0)"
                fill="#294b46"
              />
            </g>
          </g>
        </svg>
        <p className="text-primary-500 uppercase text-sm font-bold mt-3">Chargement des données</p>
      </div>
    )

  return (
    <div className="max-w-screen flex flex-col" style={{ height: innerHeight, maxHeight: innerHeight }}>
      <div className="h-16 bg-white border-b border-gray-300 flex-shrink-0 flex items-center justify-between px-6">
        <div className="flex items-center">
          <div className="flex flex-row items-center">
            <svg id="Groupe_3" width="44" height="44" viewBox="0 0 44 44">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_1" data-name="Rectangle 1" width="17.913" height="25.171" fill="#fff" />
                </clipPath>
              </defs>
              <circle id="Ellipse_1" data-name="Ellipse 1" cx="22" cy="22" r="22" fill="#E8F412" />
              <g id="Groupe_2" data-name="Groupe 2" transform="translate(12 9)">
                <g id="Groupe_1" data-name="Groupe 1" transform="translate(0 0)" clipPath="url(#clip-path)">
                  <path
                    id="Tracé_1"
                    data-name="Tracé 1"
                    d="M12.285,11.779a18.432,18.432,0,0,0-2.33-.187c-2.966,0-3.964,2.216-3.964,5.181,0,2.185.787,4.808,3.632,4.87a15.353,15.353,0,0,0,2.662-.157Zm5.628,12.892c-.484.03-4.782.5-7.778.5a13.759,13.759,0,0,1-5.627-.749C1.482,23.078,0,20.019,0,16.711c0-3.277,1.421-6.586,4.447-7.959a10.937,10.937,0,0,1,4.146-.687,28.211,28.211,0,0,1,3.662.25V1.042L17.913,0Z"
                    transform="translate(0 0)"
                    fill="#294b46"
                  />
                </g>
              </g>
            </svg>
            <p className="ml-3 text-md lg:text-lg font-bold uppercase leading-none">
              Salle de réunion
              <br />
              <span className="text-xs leading-none hidden md:block">227 rue Jean Jaurès</span>
            </p>
          </div>
          <span className="rounded-md shadow-sm ml-3 lg:ml-20 inline-flex">
            <button
              type="button"
              className=" items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-primary-300 focus:shadow-outline-primary active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150 hidden md:inline-flex"
              onClick={() => {
                CalendarRef.current.getApi().today()
              }}>
              Aujourd'hui
            </button>
          </span>
          <span className="relative z-0 inline-flex shadow-sm ml-6">
            <button
              type="button"
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-primary-300 focus:shadow-outline-primary active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
              aria-label="Previous"
              onClick={() => {
                CalendarRef.current.getApi().prev()
              }}>
              {/* Heroicon name: chevron-left */}
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            </button>
            <button
              type="button"
              className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-primary-300 focus:shadow-outline-primary active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
              aria-label="Next"
              onClick={() => {
                CalendarRef.current.getApi().next()
              }}>
              {/* Heroicon name: chevron-right */}
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
              </svg>
            </button>
          </span>
          <span className="ml-6 text-primary-500 hidden xl:block xl:text-xl mr-6">{dateDisplay}</span>
        </div>
        <div className="block">
          <div className="relative text-left z-10">
            <MyListBox updateView={updateView} setCurrentView={setCurrentView} />
          </div>
        </div>
      </div>
      <div className="border-b border-gray-300 py-4 px-3 justify-start md:justify-center flex xl:hidden">
        <span className="text-gray-700 text-base">{dateDisplay}</span>
      </div>
      <div className="flex flex-grow" style={{ height: calendarHeight }}>
        <div className="flex-shrink-0 border-r flex-col items-start max-h-full relative p-6 pb-12 hidden md:flex width-col" style={{ height: calendarHeight }}>
          <div className="datepicker flex-grow">
            <SimpleReactCalendar
              weekStartsOn={1}
              headerPrevTitle="Mois précédent"
              headerNextTitle="Mois suivant"
              daysOfWeek={["lun.", "mar.", "mer.", "jeu.", "ven.", "sam.", "dim."]}
              months={["lun.", "mar.", "mer.", "jeu.", "ven.", "sam.", "dim."]}
              ref={MiniCalendarRef}
              blockClassName=""
              activeMonth={currentDate}
              selected={currentDate}
              onSelect={(e) => {
                CalendarRef.current.getApi().gotoDate(e)
                setCurrentDate(e)
              }}
              //onMonthChange={(e) => CalendarRef.current.getApi().gotoDate(e)}
            />
          </div>
          <div
            className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            id="trash-event"
            ref={TrashRef}>
            <svg className="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
              />
            </svg>
            <span className="mt-2 block text-sm font-medium text-gray-900">Glisser ici pour supprimer</span>
          </div>
          {/* <div className="w-full">
            <h3 className=" text-gray-700 font-normal pb-3 border-b border-gray-300">Filtrer les calendriers par profil</h3>
            <MySwitch title="Producteur - Récoltant" color="primary" switchValue={producteurRecoltant} setSwitchValue={setProducteurRecoltant} />
            <MySwitch title="Négociant" color="secondary" switchValue={negociant} setSwitchValue={setNegociant} />
            <MySwitch title="Négociant - Vinificateur" color="tertiary" switchValue={negociantVinificateur} setSwitchValue={setNegociantVinificateur} />
            <MySwitch title="Viticulteur ou exploitant agricole" color="quartary" switchValue={viticulteurExploitant} setSwitchValue={setViticulteurExploitant} />
          </div> */}
        </div>
        <div className="flex-grow flex-shrink-0" style={{ height: calendarHeight }}>
          <FullCalendar
            contentHeight={calendarHeight}
            ref={CalendarRef}
            initialView={currentView}
            themeSystem="standard"
            locale="fr"
            firstDay={1}
            expandRows={false}
            headerToolbar={false}
            allDaySlot={false}
            buttonText={{
              today: "Aujourd'hui",
              month: "month",
              week: "week",
              day: "day",
              list: "list",
            }}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
            editable={true}
            droppable={false}
            selectable={true}
            selectOverlap={false}
            eventSources={[
              events,
              [
                {
                  backgroundColor: "#E8F412",
                  borderColor: "#E8F412",
                  textColor: "#294B46",
                  daysOfWeek: [1],
                  editable: false,
                  title: "Réunion hebdo Declik",
                  startTime: "09:00:00",
                  endTime: "10:00:00",
                  extendedProps: {
                    accentColor: "quartary",
                  },
                },
              ],
            ]}
            noEventsContent="Pas d'événement à afficher"
            allDayText="Toute la journée"
            datesSet={(dateInfo) => {
              setDateInfo(dateInfo)
            }}
            select={(selectInfo) => {
              if (selectInfo.allDay) {
                // on ne fait rien si sélection sur la vue "mois"
                return
              }
              addEvent(selectInfo)
            }}
            dateClick={(dateClickInfo) => {
              // Que d'alle
            }}
            eventDrop={(selectInfo) => {
              console.log(selectInfo.el)
              updateEvent(selectInfo)
            }}
            eventDragStop={(event) => {
              //console.log(event)
              if (isEventOverDiv(event.jsEvent.clientX, event.jsEvent.clientY)) {
                deleteEvent(event)
              }
            }}
            eventResize={(selectInfo) => {
              updateEvent(selectInfo)
            }}
            eventLeave={(event) => {
              console.log(event.draggedEl)
            }}
            drop={(dropInfo) => {
              console.log(dropInfo)
            }}
            // events={this.formatEvents()}
          />
        </div>
      </div>
    </div>
  )
}

export default App
