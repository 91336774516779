import React from "react"
import ReactDOM from "react-dom"

import { BrowserRouter, Switch, Route } from "react-router-dom"
import App from "./App"

const routes = (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={App} />
    </Switch>
  </BrowserRouter>
)

ReactDOM.render(routes, document.getElementById("root"))
