import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyDB3-v8pHoeZcHwZL5QWiV4oI0epkT3to4",
  authDomain: "declik-reu-calendar.firebaseapp.com",
  projectId: "declik-reu-calendar",
  storageBucket: "declik-reu-calendar.appspot.com",
  messagingSenderId: "218480073479",
  appId: "1:218480073479:web:d458abd9a9fee2921183a2",
}

export const app = initializeApp(firebaseConfig)
export const database = getFirestore(app)
