import React, { useState } from "react"
import { Listbox, Transition } from "@headlessui/react"

export default function (props) {
  const [views] = useState([["Mois"], ["Semaine"]])

  const [selectedView, setSelectedView] = useState(views[1])

  const updateVals = (val) => {
    props.updateView(val)
    setSelectedView(val)
  }

  return (
    <div className="flex items-center justify-center listbox">
      <div className="w-full max-w-xs mx-auto">
        <Listbox as="div" className="space-y-1" value={selectedView} onChange={updateVals}>
          {({ open }) => (
            <div className="flex items-center">
              <Listbox.Label className="text-sm leading-5 font-light text-gray-700 mr-3 hidden sm:block">Affichage</Listbox.Label>
              <div className="relative w-36 sm:w-48">
                <span className="inline-block w-full rounded-md shadow-sm">
                  <Listbox.Button className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-primary focus:border-primary-300 transition ease-in-out duration-150 text-sm sm:leading-5">
                    <span className="block truncate">{selectedView}</span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                        <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>
                  </Listbox.Button>
                </span>

                <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" className="absolute mt-1 w-full rounded-md bg-white shadow-lg">
                  <Listbox.Options static className="max-h-60 rounded-md py-1 leading-6 shadow-xs overflow-auto focus:outline-none text-sm sm:leading-5">
                    {views.map((view) => (
                      <Listbox.Option key={view} value={view}>
                        {({ selected, active }) => (
                          <div className={`${active ? "text-white bg-primary-500" : "text-gray-900"} cursor-default select-none relative py-2 pl-8 pr-4`}>
                            <span className={`${selected ? "font-semibold" : "font-normal"} block truncate`}>{view}</span>
                            {selected && (
                              <span className={`${active ? "text-white" : "text-primary-500"} absolute inset-y-0 left-0 flex items-center pl-1.5`}>
                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                </svg>
                              </span>
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </div>
          )}
        </Listbox>
      </div>
    </div>
  )
}
